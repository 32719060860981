import { viewport } from '@popperjs/core';
import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import Swiper from 'swiper';
import { Navigation, Pagination, Grid } from 'swiper/modules';
// we define a minimal configuration

var Isotope = require('isotope-layout');


export default {
    init() {
        // JavaScript to be fired on all pages

        window.klaro = Klaro;
        Klaro.setup(window.klaroConfig);

        var qsRegex;

        if(document.querySelectorAll('.grid--articles').length > 0 ) {
            var $isoArticles = new Isotope( '.grid--articles', {
                itemSelector: '.grid__elem',
                layoutMode: 'fitRows',
                hiddenStyle: {
                    opacity: 0,
                },
                visibleStyle: {
                    opacity: 1,
                },
                transitionDuration: '0.8s',
                filter: function () {
                    if($(this).data('keywords').match(qsRegex) || $(this).data('tags').match(qsRegex) || $(this).data('categories').match(qsRegex)) {
                        return true;
                    }
                },
            });
        }


        // Opening the mobile menu
        $('#menu-button').on('click', () => {
            toggleMenu();
        });

        const toggleMenu = () => {
            $('#menu-button').toggleClass('open');
            $('body').toggleClass( 'mobile-menu-open' );
            $('.nav-overlay').toggleClass('open');

            var text = $('#menu-button').attr('aria-label');
            if(text == 'Open mobile navigation menu') {
                $('#menu-button').attr('aria-label', 'Close mobile navigation menu');
                $('#menu-button').attr('aria-expanded', true);
                setTimeout(function(){$('.nav--main').fadeIn()}, 500);
            } else {
                $('#menu-button').attr('aria-label', 'Open mobile navigation menu');
                $('#menu-button').attr('aria-expanded', false);
                $('.menu-item').removeClass('show').removeClass('faded');
                $('.sub-menu').removeClass('show');
                $('a.dropdown-toggle').removeClass('show');
                $('ul.dropdown-menu').removeClass('show');
                $('.nav--main').fadeOut();
            }
        };
        // Opening the mobile menu

        if(window.innerWidth > 992) {
            $('nav .menu-item > a').on('mouseover', function (event) {
                event.preventDefault();
                if( $(this).siblings('ul.sub-menu').find('.mobmenu-back').length === 0 ) {
                    $(this).siblings('ul.sub-menu').append('<span class="mobmenu-back"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path id="Icon_ionic-ios-arrow-dropleft-circle" data-name="Icon ionic-ios-arrow-dropleft-circle" d="M23.375,3.375a20,20,0,1,0,20,20A20,20,0,0,0,23.375,3.375Zm4.173,27.8a1.863,1.863,0,0,1,0,2.625,1.833,1.833,0,0,1-1.308.538,1.865,1.865,0,0,1-1.317-.548l-9.038-9.067a1.854,1.854,0,0,1,.058-2.558l9.173-9.2a1.856,1.856,0,0,1,2.625,2.625l-7.875,7.788Z" transform="translate(-3.375 -3.375)" fill="#ed9500"/></svg></span>');
                }
                $('.sub-menu ul.sub-menu').removeClass('show');
                $(this).parent().removeClass('faded');
                $(this).parent().siblings().removeClass('show');
                $(this).parent().siblings().addClass('faded');
                $(this).parent().siblings().find('.sub-menu').removeClass('show');
                $(this).parent().siblings().find('.sub-menu > li').removeClass('show').removeClass('faded');
                $(this).parent().addClass('show');
                if($(this).parent().hasClass('menu-item-has-children')) {
                    $(this).next().addClass('show');
                } else {
                    $(this).parent().siblings().removeClass('faded');
                    $(this).closest('.sub-menu').addClass('show');
                }
            });
        } else {
            $('nav .menu-item-has-children > a').on('click', function (event) {
                event.preventDefault();
                if( $(this).siblings('ul.sub-menu').find('.mobmenu-back').length === 0 ) {
                    $(this).siblings('ul.sub-menu').append('<span class="mobmenu-back"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path id="Icon_ionic-ios-arrow-dropleft-circle" data-name="Icon ionic-ios-arrow-dropleft-circle" d="M23.375,3.375a20,20,0,1,0,20,20A20,20,0,0,0,23.375,3.375Zm4.173,27.8a1.863,1.863,0,0,1,0,2.625,1.833,1.833,0,0,1-1.308.538,1.865,1.865,0,0,1-1.317-.548l-9.038-9.067a1.854,1.854,0,0,1,.058-2.558l9.173-9.2a1.856,1.856,0,0,1,2.625,2.625l-7.875,7.788Z" transform="translate(-3.375 -3.375)" fill="#ed9500"/></svg></span>');
                }
                $('.sub-menu ul.sub-menu').removeClass('show');
                $(this).parent().removeClass('faded');
                $(this).parent().siblings().removeClass('show');
                $(this).parent().siblings().addClass('faded');
                $(this).next().addClass('show');
                $(this).parent().addClass('show');
            });
        }


        $('nav').on('click', '.mobmenu-back', function () {
            $(this).parent().removeClass('show');
            $(this).next().removeClass('show');

            $(this).parent().closest('.menu-item').removeClass('show');
            $(this).parent().closest('.menu-item').siblings().removeClass('faded');
            $(this).closest('a.dropdown-toggle').removeClass('show');
            $(this).closest('ul.dropdown-menu').removeClass('show');
        });
        // Opening the header menu dropdowns

        function addPaddingToNavWrapper() {
            const navWrapper = document.getElementById('nav-wrapper');
            const container = document.getElementById('nav-wrapper-padding');

            if (!navWrapper || !container) {
                return;
            }

            const padding = window.getComputedStyle(container, null).getPropertyValue('padding-right');
            const rect = container.getBoundingClientRect();
            const containerMargin = 12;
            const margin = rect.left + containerMargin;

            if (padding === '0px') {
                navWrapper.style.paddingRight = margin + 'px';
            } else {
                navWrapper.style.paddingRight = padding;
            }

        }

        addPaddingToNavWrapper();
        window.addEventListener('resize', addPaddingToNavWrapper);

        function darkHeroShowHideHeader() {
            const darkHero = document.getElementById('dark-hero');
            const header = document.getElementById('header');

            if (!darkHero || !header) {
                return;
            }

            header.classList.add('header--fixed');
            const rect = darkHero.getBoundingClientRect();
            const headerHeight = 144;
            const headerOffScreen = (rect.height - window.scrollY) < headerHeight;

            if (headerOffScreen) {
                header.classList.add('hide');
            } else {
                header.classList.remove('hide');
            }
        }

        darkHeroShowHideHeader();
        document.addEventListener('scroll', darkHeroShowHideHeader);
        window.addEventListener('resize', darkHeroShowHideHeader);

        function fixStickyHeaderOverflow() {
            const sticky = document.getElementById('dark-hero');

            if (!sticky) {
                return;
            }

            const inner = sticky.querySelector('.split-header__inner');

            if (!inner) {
                return;
            }

            const innerHeight = inner.scrollHeight;
            const computedStyle = window.getComputedStyle(sticky, null);
            const paddingTop = parseFloat(computedStyle.getPropertyValue('padding-top'));
            const paddingBottom = parseFloat(computedStyle.getPropertyValue('padding-bottom'));
            const availableHeight = sticky.offsetHeight - paddingTop - paddingBottom;

            if (innerHeight > availableHeight) {
                inner.classList.add('sticky-header__inner--overflow');
                const minHeight = innerHeight + paddingTop + paddingBottom;
                sticky.style.minHeight = minHeight + 'px';

            } else {
                inner.classList.remove('sticky-header__inner--overflow');
                sticky.style.minHeight = 'px';
            }
        }

        fixStickyHeaderOverflow();
        window.addEventListener('resize', fixStickyHeaderOverflow);

        function changeHeaderTextIfDarkHero() {
            const darkHero = document.getElementById('dark-hero');
            if (darkHero) {
                document.body.classList.add('dark-hero');
            }
        }

        changeHeaderTextIfDarkHero();

        $('.header__search-toggle').on('click', function() {
            $('.header__search').toggleClass('active');
        });

        const testimonialSlider = new Swiper('.testimonials__list', {
            modules: [Navigation],
            navigation: {
                nextEl: '.testimonial-button-next',
                prevEl: '.testimonial-button-prev',
            },
            slidesPerView: 1,
            spaceBetween: 200,
            speed: 1000,
        });

        const awardsSlider = new Swiper('.awards__list', {
            modules: [Navigation],
            navigation: {
                nextEl: '.awards-button-next',
                prevEl: '.awards-button-prev',
            },
            slidesPerView: 1,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
            },
            spaceBetween: 20,
            speed: 1000,
        });

        const newsSlider = new Swiper('.news-articles__swiper', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.news-button-next',
                prevEl: '.news-button-prev',
            },
            pagination: {
                el: '.news-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
            },
        });

        const eventsSlider = new Swiper('.events-blocks__swiper', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.news-button-next',
                prevEl: '.news-button-prev',
            },
            pagination: {
                el: '.news-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                },
            },
        });

        const clientsSlider = new Swiper('.our-clients__list', {
            modules: [Pagination, Grid],
            slidesPerView: 2,
            spaceBetween: 40,
            speed: 700,
            grid: {
                rows: 2,
            },
            autoplay: {
                delay: 1000,
            },
            pagination: {
                el: '.clients-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    grid: {
                        rows: 1,
                    },
                    spaceBetween: 80,
                },
                992: {
                    slidesPerView: 4,
                    grid: {
                        rows: 1,
                    },
                    spaceBetween: 80,
                },
                1200: {
                    slidesPerView: 5,
                    grid: {
                        rows: 1,
                    },
                    spaceBetween: 80,
                },
                1400: {
                    slidesPerView: 6,
                    grid: {
                        rows: 1,
                    },
                    spaceBetween: 80,
                },
            },
        });
        const sectionSlider = new Swiper('.section-slider__slider--3', {
            modules: [Navigation,Pagination],
            slidesPerView: 1,
            spaceBetween: 32,
            speed: 700,
            navigation: {
                nextEl: document.querySelector('.section-slider__slider--3-next'),
                prevEl: document.querySelector('.section-slider__slider--3-prev'),
            },
            pagination: {
                el: document.querySelector('.section-slider__slider--3-pagination'),
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
        });

        const sectionSliderAlt = new Swiper('.section-slider__slider--1', {
            modules: [Navigation],
            navigation: {
                nextEl: '.section-slider-button-next',
                prevEl: '.section-slider-button-prev',
            },
            slidesPerView: 1,
            spaceBetween: 32,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 1,
                },
                992: {
                    slidesPerView: 1,
                },
                1200: {
                    slidesPerView: 1,
                },
            },
        });

        const secondaryTextSlider = new Swiper('.secondary-text__slider', {
            modules: [Pagination],
            pagination: {
                el: '.secondary-text-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 32,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
        });

        const tertiaryTextSlider = new Swiper('.tertiary-text__slider', {
            modules: [Pagination],
            pagination: {
                el: '.tertiary-text-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 32,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
        });

        const relatedPostsSlider = new Swiper('.related-posts__slider', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.related-posts-button-next',
                prevEl: '.related-posts-button-prev',
            },
            pagination: {
                el: '.related-posts-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 32,
            speed: 700,
            breakpoints: {
                992: {
                    slidesPerView: 2,
                },
            },
        });

        const partnersSlider = new Swiper('.partners__items', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.partners-button-next',
                prevEl: '.partners-button-prev',
            },
            pagination: {
                el: '.partners-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 96,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 96,
                },
            },
        });

        const positionSlider = new Swiper('.position-slider__items', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.position-button-next',
                prevEl: '.position-button-prev',
            },
            pagination: {
                el: '.position-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                },
            },
        });

        const homeHeaderSlider = new Swiper('.home-header__slider', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.home-header-button-next',
                prevEl: '.home-header-button-prev',
            },
            pagination: {
                el: '.home-header-pagination',
                type: 'bullets',
                clickable: true,
            },
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 700,
        });

        document.querySelectorAll('.our-people__people').forEach(function(thisElement){
            const peopleSlider = new Swiper(thisElement, {
                modules: [Navigation, Pagination],
                navigation: {
                    nextEl: thisElement.parentElement.querySelector('.custom-swiper-button-next'),
                    prevEl: thisElement.parentElement.querySelector('.custom-swiper-button-prev'),
                },
                pagination: {
                    el: thisElement.parentElement.querySelector('.custom-swiper-pagination'),
                    type: 'bullets',
                    clickable: true,
                },
                slidesPerView: 1,
                spaceBetween: 30,
                speed: 700,
                observer: true,
                observeParents: true,
                hashNavigation: true,
                breakpoints: {
                    768: {
                        slidesPerView: 1.4,
                        spaceBetween: 80,
                    },
                    992: {
                        slidesPerView: 1.4,
                        spaceBetween: 120,
                    },
                    1200: {
                        slidesPerView: 1.3,
                        spaceBetween: 120,
                    },
                },
                on: {
                    init: function () {
                        var profileHash = window.location.hash;
                        const targetID = profileHash.substring(1)
                        if(profileHash != '') {
                            var hashedSlideIndex = $('.our-people__people-item[data-hash="'+targetID+'"]').index();

                            this.slideTo(hashedSlideIndex);
                        }
                    },
                },
            });
            let slideCount = thisElement.querySelector('.our-people__people-item').length;
            if(slideCount <= 1) {
                peopleSlider.loopDestroy();
                peopleSlider.disable();
            }
        });

        const jobsSlider = new Swiper('.jobs-slider__items', {
            modules: [Navigation],
            navigation: {
                nextEl: '.jobs-button-next',
                prevEl: '.jobs-button-prev',
            },
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 700,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 35,
                },
            },
        });

        $('.accordion-button').on('click', function() {
            if($(this).hasClass('open')) {
                $('.accordion-button').removeClass('open');
                $(this).parent().siblings().removeClass('show');
            } else {
                $('.accordion-button').removeClass('open');
                $(this).addClass('open');
            }
        })

        function handleLoadMore(selector, startingAmount) {
            if(window.innerWidth > 992) {
                $(selector).slice(0, startingAmount).show(function(){
                    $(this).css('display', 'flex');
                    if ($(selector + ':hidden').length <= 0) {
                        $('#loadMore').hide();
                    }
                });
            } else {
                $(selector).slice(0, 6).show(function(){
                    $(this).css('display', 'flex');
                    if ($(selector + ':hidden').length <= 0) {
                        $('#loadMore').hide();
                    }
                });
            }
            $('#loadMore').on('click', function (e) {
                e.preventDefault();
                $(selector + ':hidden').slice(0, 2).fadeIn().css('display', 'flex');
                if ($(selector + ':hidden').length <= 0) {
                    $('#loadMore').hide();
                }
            });
        }

        function handleReportsLoadMore(selector, startingAmount) {
            if(window.innerWidth > 992) {
                $(selector).slice(startingAmount).hide(function(){
                    if ($(selector + ':hidden').length <= 0) {
                        $('#loadMore').hide();
                    }
                });
            } else {
                $(selector).slice(2).hide(function(){
                    if ($(selector + ':hidden').length <= 0) {
                        $('#loadMore').hide();
                    }
                });
            }
            $('#loadMore').on('click', function (e) {
                e.preventDefault();
                $(selector + ':hidden').slice(0, 2).fadeIn().css('display', 'flex');
                if ($(selector).is(':hidden')) {
                    $('#loadMore').show();
                } else {
                    $('#loadMore').hide();
                }

                setTimeout(() => { $isoArticles.arrange(); }, 500);
            });
        }

        if($('.related-posts__item')) {
            handleLoadMore('.related-posts__item', 2);
        }

        if($('.insight-reports__item').length > 0) {
            handleReportsLoadMore('.insight-reports__item', 8);

            setTimeout(() => { $isoArticles.arrange(); }, 500);
        }

        if($('.news-article-list').length > 0) {
            handleReportsLoadMore('.news-article-list', 4);

            setTimeout(() => { $isoArticles.arrange(); }, 500);
        }

        var initShow = 8;
        var counter = initShow;


        //when load more button clicked
        $('#load-more').on('click', function() {
            counter = counter + initShow;
            handleLoadMore(counter);
        });

        if($('.form--filter')) {
            $('.form--filter').on('submit', function (e) {
                e.preventDefault()

                var input = $(this).find('.quicksearch'),
                    fieldValues = input.val(),
                    regexValue = fieldValues.trim().replace(' ', '|');

                qsRegex = new RegExp(regexValue, 'i');

                $isoArticles.arrange();
                if ($('.news-article-list').is(':hidden').length > 0) {
                    $('#loadMore').show();
                } else {
                    $('#loadMore').hide();
                }
                if (
                    ($('#filterInsightsReports').value !== '' && $('#filterInsightsReports').value !== 'All')
                    || fieldValues !== ''
                ) {
                    $('.js-clear').removeClass('btn--inactive');
                } else {
                    $('.js-clear').addClass('btn--inactive');
                }
            });
        }

        if($('.form--news-filter')) {
            $('.form--news-filter').on('submit', function (e) {
                e.preventDefault()

                var selectCurrentValueText = $('#filterNewsArticles option:selected').val(),
                    regexValue = selectCurrentValueText.trim().replace(' ', '|');

                qsRegex = new RegExp(regexValue, 'i');

                $isoArticles.arrange();
                if ($('.news-article-list').is(':hidden').length > 0) {
                    $('#loadMore').show();
                } else {
                    $('#loadMore').hide();
                }
                if ($('#filterNewsArticles').value !== '' && $('#filterNewsArticles').value !== 'All') {
                    $('.js-clear').removeClass('btn--inactive');
                } else {
                    $('.js-clear').addClass('btn--inactive');
                }
            });
        }

        $('.js-clear').on('click', function (e) {
            e.preventDefault()

            var form = $(this).closest('form'),
                input = $(this).closest('form').find('.quicksearch'),
                select = $(this).closest('form').find('#filterInsightsReports'),
                newsSelect = $(this).closest('form').find('#filterNewsArticles');


            if(input.length > 0) {
                input.val('');
            }
            if(select.length > 0) {
                select.prop('selectedIndex',0);
            }
            if(newsSelect.length > 0) {
                newsSelect.prop('selectedIndex',0);
            }

            form.trigger('submit');

            $('.js-clear').addClass('btn--inactive');
        });

        $('.ceo-message__read-more').on('click', function(e) {
            e.preventDefault();

            $('.ceo-message__content').toggleClass('expanded');
            if($('.ceo-message__content').hasClass('expanded')) {
                $(this).text('Read less...');
            } else {
                $(this).text('Read more...');
            }
        });

        $('.diversity-read-more').on('click', function(e) {
            e.preventDefault();

            $('.expandable').toggleClass('expanded');
            if($('.expandable').hasClass('expanded')) {
                $(this).text('Read less...');
            } else {
                $(this).text('Read more...');
            }
        });

        $('#apply-btn').on('click', function(e) {
            e.preventDefault();

            if($('.apply-modal')) {
                $('.apply-modal').addClass('open');
            }
        });

        $('#apply-close').on('click', function(e) {
            e.preventDefault();

            if($('.apply-modal')) {
                $('.apply-modal').removeClass('open');
            }
        });

        $('.cv input').on('change', function(e){
            if($(this).value != '') {
                const fileName = $(this).val().split('\\').pop();
                $(this).parent().parent().addClass('uploaded');
                $(this).parent().parent().find('label span').text(fileName);
            } else {
                $(this).parent().parent().removeClass('uploaded');
                $(this).parent().parent().find('label span').text('Upload CV');
            }
        });

        $('.cover-letter input').on('change', function(e){
            if($(this).value != '') {
                const fileName = $(this).val().split('\\').pop();
                $(this).parent().parent().addClass('uploaded');
                $(this).parent().parent().find('label span').text(fileName);
            } else {
                $(this).parent().parent().removeClass('uploaded');
                $(this).parent().parent().find('label span').text('Upload Cover Letter');
            }
        });

        $('.remove-file').on('click', function(e) {
            const wrapperElement = $(this).parent().parent();
            e.preventDefault();

            wrapperElement.removeClass('uploaded');
            wrapperElement.find('input').val('');

            if(wrapperElement.hasClass('cv-wrapper')) {
                wrapperElement.find('label span').text('Upload CV');
            } else if(wrapperElement.hasClass('cover-letter-wrapper')) {
                wrapperElement.find('label span').text('Upload Cover Letter');
            }
        });

        $('#apply-submit').on('click', function(e) {
            if($('.form--apply')) {
                $('.apply-modal__inner').addClass('thank-you');
            }
        })

        if($('.accordion-item .swiper-wrapper')) {
            $('.accordion-item .swiper-wrapper').each(function() {
                if($(this).children().length == 0 ) {
                    $(this).closest('.accordion-item').hide();
                }
            })
        }

        if($('.our-people')) {
            var profileHash = window.location.hash;
            const targetID = profileHash.substring(1)
            if(profileHash != '') {
                setTimeout(function(){
                    $('.accordion-item').collapse('hide');
                    $('.accordion-item').each(function(){
                        if($(this).find('.our-people__people-item[data-hash="'+targetID+'"]').length > 0) {
                            var accordionID = $(this).find('.accordion-collapse').attr('id');
                            if(accordionID != 'collapse0'){
                                setTimeout(function(){
                                    $('.accordion-button[data-bs-target="#'+accordionID+'"]').trigger('click');
                                    console.log('triggered')
                                },200);
                            } else {
                                $('.accordion-item:first-child').find('.our-people__section').click();
                            }
                            setTimeout(function(){
                                $([document.documentElement, document.body]).animate({
                                    scrollTop: $(profileHash).offset().top - 300,
                                },300);
                            }, 200);
                            return false;
                        }
                    });
                }, 100);
            } else {
                $('.accordion-item:first-child').find('.our-people__section').click();
            }
        }

        if($('.our-people__people')) {
            $('.our-people__people').each(function() {
                var slideCount = $(this).find('.swiper-slide').length;

                if(slideCount <= 1) {
                    $(this).siblings('.custom-swiper-controls').hide();
                } else {
                    $(this).siblings('.custom-swiper-controls').show();
                }
            })

        }

        // Detect elements coming into view and add class
        var observer;
        const ivElements = document.querySelectorAll('.iv');
        observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('iv-loaded');
                }
            });
        }, { threshold: [0.5] });

        ivElements.forEach(function(image) {
            observer.observe(image);
        });
        // Detect elements coming into view and add class

        function animateValue(obj, start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

        let keyFactValues = document.querySelectorAll('.js-key-value');
        if (keyFactValues.length > 0) {
            let firstFactValue = keyFactValues[0].innerHTML;
            let secondFactValue = keyFactValues[1].innerHTML;
            let thirdFactValue = keyFactValues[2].innerHTML;

            observer = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.intersectionRatio > 0) {
                        for (let i = 0; keyFactValues.length; i++) {
                            if (i == 0) {
                                if (!keyFactValues[i].classList.contains('complete')) {
                                    animateValue(keyFactValues[i], 0, firstFactValue, 1000);
                                    keyFactValues[i].classList.add('complete');
                                }
                            } else if (i == 1) {
                                if (!keyFactValues[i].classList.contains('complete')) {
                                    animateValue(keyFactValues[i], 0, secondFactValue, 1000);
                                    keyFactValues[i].classList.add('complete');
                                }
                            } else if (i == 2) {
                                if (!keyFactValues[i].classList.contains('complete')) {
                                    animateValue(keyFactValues[i], 0, thirdFactValue, 1000);
                                    keyFactValues[i].classList.add('complete');
                                }
                            } else {
                                break;
                            }
                        }
                    }
                });
            }, { threshold: [0] });

            keyFactValues.forEach(function (image) {
                observer.observe(image);
            });
        }

        $('.language-selector__button').on('click', function() {
            var currOptions = $( '.language-selector__options' );

            if (currOptions.hasClass('open')) {
                currOptions.removeClass('open');
            } else {
                currOptions.addClass('open');
            }
        });

        $(document).on('click', function(event) {
            var $target = $(event.target);
            if(!$target.closest('#language-selector').length && $('.language-selector__options').hasClass('open')) {
                $('.language-selector__options').removeClass('open');
            }
        });

        if($('.featured-news__details datetime')) {
            $('.featured-news__details datetime').each(function(){
                translateAsianDate($(this));
            });
        }

        if($('.events-blocks__article-content datetime')) {
            $('.events-blocks__article-content datetime').each(function(){
                translateAsianDate($(this));
            });
        }

        if($('.post-info time')) {
            translateAsianDate($('.post-info time'));
        }

        if($('.event-time')) {
            translateAsianDate($('.event-time'));
        }

        if($('.jobs-list__date')) {
            $('.jobs-list__date').each(function(){
                translateAsianDate($(this));
            });
        }

        if($('.single-job_listings__date')) {
            translateAsianDate($('.single-job_listings__date'));
        }

        function translateAsianDate(element) {
            let timeText = element.text().replace('12月', 'December')
            .replace('11月', 'November')
            .replace('10月', 'October')
            .replace('1月', 'January')
            .replace('2月', 'February')
            .replace('3月', 'March')
            .replace('4月', 'April')
            .replace('5月', 'May')
            .replace('6月', 'June')
            .replace('7月', 'July')
            .replace('8月', 'August')
            .replace('9月', 'September');

            element.text(timeText);
        }

        // JavaScript to be fired on the contact page
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            let formDataLayer = null;

            switch (Number(event.detail.contactFormId)) {
                case 5:
                    formDataLayer = {
                        'event' : 'form_submit',
                        'form_type' : 'Contact Us - EU',
                        'form_label' : 'Contact',
                    };
                    break;

                case 895:
                    formDataLayer = {
                        'event' : 'form_submit',
                        'form_type' : 'Contact Us - Asia',
                        'form_label' : 'Contact',
                    };
                    break;

                case 893:
                    formDataLayer = {
                        'event' : 'form_submit',
                        'form_type' : 'Contact Us - US',
                        'form_label' : 'Contact',
                    };
                    break;

                case 608:
                    formDataLayer = {
                        'event' : 'form_submit',
                        'form_type' : 'Event Form',
                        'form_label' : 'Contact',
                    };
                    break;

                case 623:
                    formDataLayer = {
                        'event' : 'form_submit',
                        'form_type' : 'Job Application',
                        'form_label' : 'Contact',
                    };
                    break;

                case 13465:
                    formDataLayer = {
                        'event' : 'form_submit',
                        'form_type' : 'Referral Form',
                        'form_label' : 'Contact',
                    };
                    break;
            }

            if (typeof formDataLayer == 'object' && null !== formDataLayer) {
                window.dataLayer.push(formDataLayer);
            }
        }, false );

        // Remove NitroPack footer link
        document.addEventListener('scroll', () => {
            const elements = document.querySelectorAll('div[style]');

            for (let i = 0, len = elements.length; i < len; i++) {
                const display = window.getComputedStyle(elements[i], null).display;
                const clear = window.getComputedStyle(elements[i], null).clear;

                if(display == 'block' && clear == 'both') {
                    elements[i].remove();
                }
            }
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
